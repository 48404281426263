import HeaderBuilder from '@/services/header-builder.js';
import axios from 'axios';
import store from '@/store';
import {
    apiBaseUrl, apiBaseUrl1
} from "@/services/api.js";
import util from "@/services/util";

const Banners = {
    _cacheKey_prefix: () => {
        return `CATEGS_T_${process.env.VUE_APP_TENANT_ID}`;
    },
    _cacheKey: (searchOptions = {}) => {
        return `${Banners._cacheKey_prefix()}_S_${JSON.stringify(searchOptions)}`;
    },
    _cached: (searchOptions = {}) => {
        return store.state.cache[Banners._cacheKey(searchOptions)];
    },
    _setCached: (searchOptions = {}, c = null) => {
        if (searchOptions === null && c === null) {
            store.commit('clearCachePrefix', Banners._cacheKey_prefix());
            return;
        }
        store.commit('setCacheKey', {
            key: Banners._cacheKey(searchOptions),
            payload: c
        });
    },
    list: async (searchOptions = {}) => {
        if (searchOptions && !searchOptions.perPage) {
            searchOptions.perPage = 10;
        }
        const params = new URLSearchParams(util.cleanObject(searchOptions));
        const cached = Banners._cached(searchOptions);
        if (cached) {
            return cached;
        }
        const response = await axios.get(`${apiBaseUrl1}/api/banner?${params.toString()}`, {
            headers: HeaderBuilder.build()
        });
        const entity = response.data;
        Banners._setCached(searchOptions, entity);
        return entity;
    },
    get: async (id, options = {}) => {
        const params = new URLSearchParams(util.cleanObject(options));
        const response = await axios.get(`${apiBaseUrl1}/api/banner/${id}?${params.toString()}`, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    create: async (payload, image) => {
        const response = await axios.put(`${apiBaseUrl1}/api/banner`, image, {
            headers: {
                ...HeaderBuilder.build(),
                ...{
                    "X-Zankh-Published": String(payload.published),
                    "X-Zankh-Name": payload.name,
                    "X-Zankh-Alternate-Text": payload.alternate_text,
                    "X-Zankh-Link": payload.link,
                },
            }
        });
        Banners._setCached(null, null);
        return response.data;
    },
    edit: async (payload, image) => {
        const response = await axios.patch(`${apiBaseUrl1}/api/banner/${payload.id}`, image, {
            headers: {
                ...HeaderBuilder.build(),
                ...{
                    "X-Zankh-Published": String(payload.published),
                    "X-Zankh-Name": payload.name,
                    "X-Zankh-Alternate-Text": payload.alternate_text,
                    "X-Zankh-Link": payload.link,
                },
            }
        });
        Banners._setCached(null, null);
        return response.data;
    },
    delete: async (id) => {
        const response = await axios.delete(`${apiBaseUrl1}/api/banner/${id}`, {
            headers: HeaderBuilder.build()
        });
        Banners._setCached(null, null);
        return response.data;
    },
    getImageUrl: (banner) => {
        return `${apiBaseUrl1}/api/banner/${banner.id}/image?apartment=${process.env.VUE_APP_TENANT_ID}&image_id=${banner.image_file_id}`;
    },
    reorder: async (banners) => {
        const response = await axios.patch(`${apiBaseUrl1}/api/banners`, {
            banners
        }, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
};

export default Banners;