var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p",on:{"click":function($event){return _vm.dispathGA4Event(_vm.product)}}},[_c('router-link',{staticClass:"container-product",attrs:{"to":{
      name: 'p-detail',
      params: {
        product_id: this.product.id,
        product_name: this.product_url_name,
      },
    }}},[_c('div',{staticClass:"fx fx-center fx-direct-cont clickable p-block"},[(_vm.tenant_id=='pcnow' && _vm.product.categories[0].name=='PC GAMER' || _vm.product.categories[0].name=='PC HOME-OFFICE')?_c('div',{staticClass:"prod-img-block fx-center"},[(_vm.product.imageUrl && _vm.product.imageUrl[0])?_c('img',{attrs:{"src":_vm.product.imageUrl[1],"alt":_vm.product.name},on:{"mouseover":function($event){return _vm.changeImage(0)},"mouseout":function($event){return _vm.changeImage(1)}}}):_vm._e()]):_c('div',{staticClass:"prod-img-block fx-center"},[(_vm.product.imageUrl && _vm.product.imageUrl[0])?_c('img',{attrs:{"src":_vm.product.imageUrl[_vm.imagePos],"alt":_vm.product.name},on:{"mouseover":function($event){return _vm.changeImage(1)},"mouseout":function($event){return _vm.changeImage(0)}}}):_vm._e()])]),_c('div',{staticClass:"fx-center txt-align-center cut-text p-name"},[_c('h2',[_vm._v(_vm._s(_vm.product.name))])])]),(_vm.$store.state.user.role == 'SUPPLIER')?_c('div',{staticClass:"icon-circle shadow clickable delete-product fx-center del-prod-trash",on:{"click":function($event){return _vm.deleteProduct(_vm.product.id)}}},[_c('i',{staticClass:"fa fa-trash",attrs:{"aria-hidden":"true"}})]):_vm._e(),_c('router-link',{staticClass:"fx fx-w txt-align-center fx-d-c fx-center",attrs:{"to":{
      name: 'p-detail',
      params: {
        product_id: this.product.id,
        product_name: this.product_url_name,
      },
    }}},[_c('div',{staticClass:"price txt-align-center"},[(!_vm.product.plotted_price)?_c('div',[(_vm.priceText)?_c('span',{staticClass:"price-text mg-1-bot"},[_vm._v(_vm._s(_vm.priceText))]):_vm._e(),(_vm.product.price_rules && _vm.product.price_rules.length >= 1)?[(_vm.showRootPrice)?_c('span',{staticClass:"price-number"},[_vm._v("\n            de "),_c('strike',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.product.price))+" ")])],1):_vm._e(),_c('span',{staticClass:"price-number"},[_vm._v("\n            por\n            "+_vm._s(_vm._f("toCurrency")((_vm.product.price * (1 - _vm.product.price_rules[0].amount / 100))))+"\n          ")]),(_vm.showPercentageDifference)?_c('span',{staticClass:"price-number promotion-percentage-seil"},[_vm._v("\n            "+_vm._s(_vm.percentageDifference)+"% off\n          ")]):_vm._e()]:[_c('span',{staticClass:"price-number"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.product.price))+" ")])],(_vm.product.price_rules && _vm.product.price_rules.length >= 1)?[(
              _vm.showInstallments &&
                _vm.max_installments &&
                Number(_vm.max_installments) > 1
            )?_c('span',{staticClass:"until-price price-number"},[_vm._v("\n            (\n            "),_c('strong',[_vm._v("\n              até "+_vm._s(_vm.max_installments)+"x de\n              "+_vm._s(_vm._f("toCurrency")(((_vm.product.price * (1 - _vm.product.price_rules[0].amount / 100)) /
                  _vm.max_installments))))]),_vm._v("\n            )\n          ")]):_vm._e()]:[(
              _vm.showInstallments &&
                _vm.max_installments &&
                Number(_vm.max_installments) > 1
            )?_c('span',{staticClass:"until-price price-number"},[_vm._v("\n            (\n            "),_c('strong',[_vm._v("\n              "+_vm._s(_vm.max_installments)+"x de\n              "),_c('span',{staticClass:"wd-100 price-number"},[_vm._v("\n                "+_vm._s(_vm._f("toCurrency")((_vm.product.price / _vm.max_installments)))+"\n              ")])]),_vm._v(")\n          ")]):_vm._e()]],2):_vm._e(),(_vm.product.plotted_price)?_c('div',[(_vm.product.price_rules && _vm.product.price_rules.length >= 1)?[(_vm.showRootPrice)?_c('span',{staticClass:"price-number"},[_vm._v("\n            de "),_c('strike',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.product.price))+" ")])],1):_vm._e(),((_vm.product.price_rules[0].modifier_type == 'PRICE'))?_c('span',{staticClass:"price-number"},[_vm._v("  "+_vm._s(_vm._f("toCurrency")((_vm.product.price - _vm.product.price_rules[0].amount)))+" ")]):_c('span',{staticClass:"price-number"},[_vm._v("\n            por\n            "+_vm._s(_vm._f("toCurrency")((_vm.product.price * (1 - _vm.product.price_rules[0].amount / 100))))+"\n          ")]),(_vm.showPercentageDifference)?_c('span',{staticClass:"price-number promotion-percentage-seil"},[_vm._v("\n            "+_vm._s(_vm.percentageDifference)+"% off\n          ")]):_vm._e()]:_c('div',{staticClass:"price-number"},[_vm._v("\n          "+_vm._s(_vm._f("toCurrency")(_vm.product.price))+"\n        ")]),_c('div',{staticClass:"wd-100 tiny-word"},[_vm._v("à vista no boleto/PIX com desconto")]),_c('div',[_c('div',{staticClass:"tiny-word"},[_vm._v("\n            "+_vm._s(_vm.max_installments_plotted)+" x\n            "+_vm._s(_vm._f("toCurrency")((_vm.product.plotted_price / _vm.max_installments_plotted)))+"\n            sem juros no cartão\n          ")])])],2):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }