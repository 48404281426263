<template>

  <div>
    <carousel
      :perPageCustom="[[768, 4], [1024, 9], [400, 3]]"
      :autoplayHoverPause="true"
      :autoplay="false"
      :paginationEnabled=false
      :touchDrag="true"
      :loop="true"
      :autoplayTimeout='4000'
    >
      <slide
        v-for="category in categoryTree"
        :key="category.id"
        class="c clickable"
      >
          <div
            class="fx card-category fx-center fx-wrap"
            @click="navigateCategoryRoute(category)"
          >
            <div class="content-image-block">
              <img class="images" :src="category.imageUrl" alt />
            </div>
            <div class="content-image">
              <h2 class="name">{{ category.name }}</h2>
            </div>
          </div>
      
      </slide>
    </carousel>
    
    <!-- <div class="slider">
      <div class="slide-track">
        <div
          v-for="category in categoryTree"
          :key="category.id"
          class="slide c clickable"
        >
          <div
            class="card-category fx-center fx-direct-cont"
            @click="navigateCategoryRoute(category)"
          >
            <div class="content-image-block">
              <img class="images" :src="category.imageUrl" alt />
            </div>
            <div class="content-image">
              <h2 class="name">{{ category.name }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.slider {
  background: pr;
  height: 150px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slider::before,
.slider::after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  height: 100px;
  width: 250px;
}

.content-image-block {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06),
    0 0 2px rgba(0, 0, 0, 0.07);
  border-radius: 100em;
}

.name {
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
}
.images {
  display: flex;
  align-items: center;
}
.card-category img.images {
  max-width: 100%;
  border-radius: 100%;
}

.VueCarousel-slide.c.clickable {
  text-align: center;
}

.VueCarousel-dot-container {
  display: none !important;
}
@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}
button.-dot {
  background-color: transparent !important;
}

.fx.card-category.fx-center.fx-wrap {
    width: 6em;
}
</style>

<script>
import router from "@/router";
import Category from "@/services/categories";
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "CarouselContainer",
  data: function () {
    return {
      categoryTree: [],
    };
  },
  beforeMount() {
    this.listCategories();
  },
  methods: {
    navigateCategoryRoute: function (category) {
      router.push({
        name: "categories",
        query: {
          root_category: category ? category.name : null
        },
      });
    },
    listCategories: function () {
      Category.getAsTree().then((categoryTree) => {
        this.categoryTree = categoryTree;
        this.$forceUpdate();
      });
    },
  },
  components: {
    Carousel,
    Slide
  },
};
</script>