<template>
  <div>
    <banner v-if="this.$route.name == 'main'" />
    <div class="container">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        :opacity="0.97"
        :z-index="2147480000"
      >
      </loading>
      <br />
      </div>
      <Carousel v-if="carousel && this.$route.name == 'main'"></Carousel>
      <div class="container">
      <FilterComponent v-if="showFilter"></FilterComponent>
      <div
        v-if="productList && productList.length"
        class="fx fx-wrap main-product-flex fx-center p-list"
        ref="plistRef"
      >

        <!-- <pre> {{ productList }}  </pre> -->

        <product-item
          v-for="product in typeof maxProducts !== 'undefined'
            ? productList.slice(0, maxProducts)
            : productList"
          :product="product"
          :key="product.id"
        ></product-item>
      </div>
      <!-- Remove filters button if (has filters), or (there is no list and we are already loaded) -->
      <div
        class="fx fx-center fx-start fx-wrap container"
        v-if="
          (!productList || !productList.length) &&
          !isLoading &&
          searchParameters &&
          (searchParameters.name ||
            (searchParameters.categories && searchParameters.categories.length))
        "
      >
        <div class="btn clickable" @click="resetSearch()">
          Confira toda a Loja
        </div>
      </div>
      <!-- <div class="fixed-buttons fixed container">
        <a
          class="p-detail-btn clickable"
          @click="$router.zhhasBefore ? $router.go(-1) : $router.push({'name': 'home'})"
        >
          <i class="fa fa-angle-left"></i>
          Voltar
        </a>
      </div> -->
      <div class="viewMore" v-if="enable_view_more">
        <div class="btn clickable mg-1-top" @click="listProducts(true)"> 
          Ver Mais 
        </div>  
      </div>
    </div>
  </div>
</template>

<style scoped>
.product {
  width: 20%;
  padding: 1em;
}

.viewMore{
  text-align: center
}

</style>

<script>
import ProductItem from "./ProductItem.vue";
import Product from "@/services/product.js";
import util from "@/services/util";
import FilterComponent from "@/components/common/Filter.vue";
import Loading from "vue-loading-overlay";
import BannerComponent from "@/components/common/BannerComponent.vue";
import Carousel from "./Carousel.vue";
import boolean from "boolean";

export default {
  name: "product-list",
  props: {
    search: Object,
    hide_banners: String,
    max_products: Number,
    show_filter: {
      type: String,
      default: process.env.VUE_APP_SHOW_FILTER,
    },
    search_categories: String,
    search_name: String,
  },
  data: function () {
    return {
      productList: [],
      searchParameters: {
        page: 0,
        perPage: 30,
        price_le: 0,
        price_ge: 0,
        ob_price: true,
        convert_prices: true,
      },
      hideBanners: false,
      maxProducts: Infinity,
      showFilter: boolean(process.env.VUE_APP_SHOW_FILTER),
      carousel: boolean(process.env.VUE_APP_INIT_CAROUSEL),
      loadingMore: false,
      over: false,
      isLoading: false,
    };
  },
  template: "#product-list-template",
  beforeMount() {
    if (this.search) {
      Object.assign(this.searchParameters, this.search);
      this.maxProducts = Infinity;
    }
    if (typeof this.search_categories !== "undefined") {
      this.searchParameters.categories = [this.search_categories];
    }
    if (typeof this.search_name !== "undefined") {
      this.searchParameters.full_text = this.search_name;
    }
    if (typeof this.hide_banners !== "undefined") {
      this.hideBanners = boolean(this.hide_banners);
      if (this.hideBanners) {
        this.maxProducts = Infinity;
      }
    }
    if (typeof this.show_filter !== "undefined") {
      this.showFilter = boolean(this.show_filter);
    }
    // If we're a supplier, we want to see everyone's products in the store
    this.searchParameters.peek_others = 1;
    // Initial product listing

    //setTimeout(() => this.listProducts(), 500);
    this.listProducts();

    

    // Add event listener for search parameter change (navbar search usually)
    this.$root.$on("searchParameters", this.onSearchParametersEvent);
    this.$root.$emit("openSearch");
    setTimeout(() => window.addEventListener("scroll", this.handleScroll), 500);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    this.$root.$off("searchParameters", this.onSearchParametersEvent);
  },
  methods: {
    onSearchParametersEvent: function (searchParameters) {
      this.$root.$emit("searchListened");
      Object.assign(this.searchParameters, searchParameters);
      this.hideBanners = true;
      this.maxProducts = Infinity;
      this.searchParameters.page = 0;
      this.loadingMore = false;
      this.over = false;
      this.listProducts();
    },
    listProducts: function (push = false) {
      
      if (push) {
        if (this.loadingMore || this.over) {
          return;
        }
        this.searchParameters.page++;
        this.loadingMore = true;
      } else {
        this.isLoading = true;
      }
      Product.listWithStock(this.searchParameters)
        .then((products) => {
          if (!this.searchParameters.ob_price) {
            util.shuffleArray(products);
          }
          if (!push) {
            this.productList = products;
          } else {
            if (products.length === 0) {
              this.over = true;
              this.enable_view_more = false;
              // alert("Não há novos produtos para exibir!");
            }

            
            this.productList.push(...products);
            this.$forceUpdate();
            setTimeout(() => (this.loadingMore = false), 100);
          }
            
        })
        .finally(() => {
          if (!push) {
            this.isLoading = false;
          }
        });
    
        
        
    
    },
    handleScroll(event) {
      if(this.enable_view_more === true){
        return;
      }
      if (!this.$refs.plistRef) {
        return;
      }
      let screenHeight = screen.height;
      let scrollTop = document.documentElement.scrollTop;
      let productListStart = util.findPos(
        this.$refs.plistRef,
        document.documentElement
      ).y;
      let productListEnd = productListStart + this.$refs.plistRef.scrollHeight;
      let diff = productListEnd - scrollTop;
      let bottomOfWindow = diff <= screenHeight * 1.2;
      if (bottomOfWindow) {
        this.listProducts(true);
      }
    },
    resetSearch() {
      this.$root.$emit("searchParameters", {
        name: "",
        categories: [],
      });
    },
  },
  watch: {
    hide_banners: function (newVal, oldVal) {
      this.hideBanners = boolean(newVal);
    },
    search: function (newVal, oldVal) {
      this.$root.$emit("searchParameters", newVal);
    },
    max_products: function (newVal, oldVal) {
      this.maxProducts = newVal;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  components: {
    "product-item": ProductItem,
    Loading,
    FilterComponent,
    banner: BannerComponent,
    Carousel,
  },
};
</script>
<style scoped>
</style>