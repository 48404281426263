<template>
  <!-- product fx fx-center -->
  <div class="p" @click="dispathGA4Event(product)">
    <router-link
      class="container-product"
      :to="{
        name: 'p-detail',
        params: {
          product_id: this.product.id,
          product_name: this.product_url_name,
        },
      }"
    >
      <div class="fx fx-center fx-direct-cont clickable p-block">

      
        <div v-if="tenant_id=='pcnow' && product.categories[0].name=='PC GAMER' || product.categories[0].name=='PC HOME-OFFICE'" class="prod-img-block fx-center">
          <img
            v-if="product.imageUrl && product.imageUrl[0]"
            :src="product.imageUrl[1]"
            :alt="product.name"
            @mouseover="changeImage(0)"
            @mouseout="changeImage(1)"
          />
        </div>

        <div v-else class="prod-img-block fx-center">
          <img
            v-if="product.imageUrl && product.imageUrl[0]"
            :src="product.imageUrl[imagePos]"
            :alt="product.name"
            @mouseover="changeImage(1)"
            @mouseout="changeImage(0)"
          />
        </div>
      </div>

      <div class="fx-center txt-align-center cut-text p-name">
        <h2>{{ product.name }}</h2>
      </div>
    </router-link>

    <div
      class="icon-circle shadow clickable delete-product fx-center del-prod-trash"
      v-if="$store.state.user.role == 'SUPPLIER'"
      v-on:click="deleteProduct(product.id)"
    >
      <i class="fa fa-trash" aria-hidden="true"></i>
    </div>

    <router-link
      :to="{
        name: 'p-detail',
        params: {
          product_id: this.product.id,
          product_name: this.product_url_name,
        },
      }"
      class="fx fx-w txt-align-center fx-d-c fx-center"
    >
      <div class="price txt-align-center">
        <div v-if="!product.plotted_price">
          <span class="price-text mg-1-bot" v-if="priceText">{{
            priceText
          }}</span>
          <template
            v-if="product.price_rules && product.price_rules.length >= 1"
          >
            <span class="price-number" v-if="showRootPrice">
              de <strike> {{ product.price | toCurrency }} </strike>
            </span>
            <span class="price-number">
              por
              {{
                (product.price * (1 - product.price_rules[0].amount / 100))
                  | toCurrency
              }}
            </span>
            <span
              class="price-number promotion-percentage-seil"
              v-if="showPercentageDifference"
            >
              {{ percentageDifference }}% off
            </span>
          </template>
          <template v-else>
            <span class="price-number"> {{ product.price | toCurrency }} </span>
          </template>

          <!-- <span
          class="price-number"
          v-if="showCheapestRule"
        >Atacado: {{ product.cheapest_rule | toCurrency }}</span> -->

          <template
            v-if="product.price_rules && product.price_rules.length >= 1"
          >
            <span
              class="until-price price-number"
              v-if="
                showInstallments &&
                  max_installments &&
                  Number(max_installments) > 1
              "
            >
              (
              <strong>
                até {{ max_installments }}x de
                {{
                  ((product.price * (1 - product.price_rules[0].amount / 100)) /
                    max_installments)
                    | toCurrency
                }}</strong
              >
              )
            </span>
          </template>

          <template v-else>
            <span
              class="until-price price-number"
              v-if="
                showInstallments &&
                  max_installments &&
                  Number(max_installments) > 1
              "
            >
              (
              <strong>
                {{ max_installments }}x de
                <span class="wd-100 price-number">
                  {{ (product.price / max_installments) | toCurrency }}
                </span> </strong
              >)
            </span>
          </template>
        </div>

        <div v-if="product.plotted_price">
          <template
            v-if="product.price_rules && product.price_rules.length >= 1"
          >
            <span class="price-number" v-if="showRootPrice">
              de <strike> {{ product.price | toCurrency }} </strike>
            </span>
            <span class="price-number" v-if="(product.price_rules[0].modifier_type == 'PRICE')">  {{ (product.price - product.price_rules[0].amount) | toCurrency }} </span>

            <span class="price-number" v-else>
              por
              {{
                (product.price * (1 - product.price_rules[0].amount / 100))
                  | toCurrency
              }}
            </span>
            <span
              class="price-number promotion-percentage-seil"
              v-if="showPercentageDifference"
            >
              {{ percentageDifference }}% off
            </span>
          </template>

          <div v-else class="price-number">
            {{ product.price | toCurrency }}
          </div>

          <div class="wd-100 tiny-word">à vista no boleto/PIX com desconto</div>
          <div>
            <div class="tiny-word">
              {{ max_installments_plotted }} x
              {{
                (product.plotted_price / max_installments_plotted) | toCurrency
              }}
              sem juros no cartão
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<style scoped>
strike {
  margin-left: 0.3em;
}
.until-price {
  font-size: 0.8em;
}
.promotion-percentage-seil {
  background: black;
  color: white !important;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 5em;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script>
import { apiBaseUrl } from "@/services/api.js";
import HeaderBuilder from "@/services/header-builder.js";
import util from "@/services/util";
import slugify from "slugify";
import boolean from "boolean";
import Features from "@/services/features";
import axios from "axios";

export default {
  name: "product-item",
  props: ["product"],
  data: function() {
    return {
      tenant_id: process.env.VUE_APP_TENANT_ID,
      imagePos: 0,
      showCheapestRuleFlag: boolean(process.env.VUE_APP_SHOW_CHEAPEST_RULE),
      priceText: process.env.VUE_APP_PRICE_TEXT || "",
      showInstallments: boolean(
        process.env.VUE_APP_SHOW_INSTALLMENTS_IN_PRODUCT_ITEM
      ),
      max_installments: null,
      firstImg:
        "https://api.zankh.com.br/api/product/60afa60c696cf617d30baac2/image/60afaad2696cf617d30baac3?apartment=madamejo",
      secImg:
        "https://api.zankh.com.br/api/product/60afa60c696cf617d30baac2/image/60afa60d696cf662d83eeb34?apartment=madamejo",
      max_installments_plotted: null,
    };
  },
  mounted() {
    Features.get().then((features) => {
      this.max_installments =
        features.payment_methods && features.payment_methods.credit_card
          ? Number(features.payment_methods.credit_card.maximum_installments)
          : null;
      this.max_installments_plotted =
        features.payment_methods && features.payment_methods.credit_card
          ? Number(
              features.payment_methods.credit_card.maximum_installments_plotted
            )
          : null;
      if (!features.payment_methods.credit_card.enabled) {
        this.showInstallments = false;
      }
    });
  },
  computed: {
    product_url_name: function() {
      return slugify(this.product.name);
    },
    // product_category_name: function () {
    //   return slugify(this.product.categories[0].name);
    // },
    showRootPrice: function() {
      return this.product.root_price_enabled && this.product.price;
    },
    showCheapestRule: function() {
      return (
        this.showCheapestRuleFlag &&
        this.product.price_rules_enabled &&
        this.product.cheapest_rule &&
        (this.product.cheapest_rule != this.product.price ||
          !this.product.root_price_enabled)
      );
    },
    showPercentageDifference: function() {
      return (
        this.showRootPrice &&
        this.showCheapestRule &&
        this.percentageDifference != 0
      );
    },
    percentageDifference: function() {
      return Math.floor(
        ((this.product.price - this.product.cheapest_rule) /
          this.product.price) *
          100
      );
    },
  },
  methods: {
    dispathGA4Event: function(product) {
      var formattedPrice = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(product.price / 100);
      var finalPrice = formattedPrice.replace("R$", "");
      finalPrice = finalPrice.replace(" ", "");

      if (this.$gtm.enabled()) {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
          event: "view_item",
          ecommerce: {
            currency: "BRL",
            value: finalPrice,
            items: [
              {
                item_id: product.id,
                item_name: product.name,
                affiliation: process.env.VUE_APP_TENANT_ID,
                currency: "BRL",
                item_category: !product.categories[0]
                  ? "sem categoria"
                  : product.categories[0].name,
                price: finalPrice,
                quantity: 1,
              },
            ],
          },
        });
      }
    },
    changeImage: function(counter) {
      if (this.product.imageUrl.length == 1) this.imagePos = 0;
      else this.imagePos = counter;
    },
    deleteProduct: function(pid) {
      this.$swal({
        title: "Tem certeza que deseja APAGAR o produto?",
        text: "Não é possível reverter essa ação!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Sim, pode apagar!",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
            dangerMode: true,
          },
        },
      }).then((result) => {
        if (!result) {
          return;
        }
        axios
          .delete(`${apiBaseUrl}/api/product/${pid}`, {
            headers: HeaderBuilder.build(),
          })
          .then(
            (response) => {
              this.$notify({
                type: "success",
                duration: 24000,
                title: "Produto Excluido",
                text: "Produto Excluido com Sucesso!",
              });
              this.$forceUpdate();
              this.productList = this.productList.filter((p) => p.id != pid);
            },
            (error) => {
              this.$notify({
                type: "error",
                duration: 24000,
                title: "Erro ao Excluir Produto",
                text: util.stringifyAxiosError(error),
              });
            }
          );
      });
      this.$forceUpdate();
    },
  },
};
</script>
