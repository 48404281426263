<template>
  <div class="filter container mg-1-top mg-1-bot pd-0">
    <!-- <div class="input-group fx-main">
        <label class="mg-right-2-em" for="filter_price_le">Preço Menor Que</label>
        <money
          class="default-border"
          type="text"
          id="filter_price_le"
          v-model="searchParameters.price_le"
          v-bind="money"
          @change.native="emitSelfSearch"
        ></money>
      </div>
      <div class="input-group fx-main">
        <label for="filter_price_ge">Preço Maior Que</label>
        <money
          class="default-border"
          type="text"
          id="filter_price_ge"
          v-model="searchParameters.price_ge"
          v-bind="money"
          @change.native="emitSelfSearch"
        ></money>
    </div>-->
    <!-- <label class="mg-right-2-em" for="filter_price_ob">Ordenar por Preço</label>
        <input
          type="radio"
          name="filter_price_ob"
          :value="false"
          v-model="searchParameters.ob_price"
          id="radio-filter_price_ob_no"
          class="form-radio"
          @change="emitSelfSearch"
        /> 
        <label for="radio-filter_price_ob_no">Não</label>
    -->
    <div class='fx fx-start p-b-1-em filter-box'>
      <div class="input-group fx-center mg-right-1-em">
        <div for="radio-filter_price_ob_asc">Menores Preços</div>
        <input
          type="radio"
          name="filter_price_ob"
          value="asc"
          v-model="searchParameters.ob_price"
          id="radio-filter_price_ob_asc"
          class="form-radio"
          @change="emitSelfSearch"
        />
      </div>
      <div class="input-group fx-center">
        <div for="radio-filter_price_ob_desc">Maiores Preços</div>
        <input
          type="radio"
          name="filter_price_ob"
          value="desc"
          v-model="searchParameters.ob_price"
          id="radio-filter_price_ob_desc"
          class="form-radio"
          @change="emitSelfSearch"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

.filter div.filter-box {
  width : 35%;
}

.filter input {
    margin-left: .5em;
}

.filter .fx.fx-start.p-b-1-em {
    border-radius: 2em;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0;
    padding: 1em;
    background: #fff !important;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06), 0 0 2px rgba(0, 0, 0, 0.07);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06), 0 0 2px rgba(0, 0, 0, 0.07);
}

</style>

<script>
import { Money } from "v-money";
import { debounce } from "debounce";

export default {
  name: "FilterComponent",
  data: function() {
    return {
      searchParameters: {
        perPage: 20,
        price_le: 0,
        price_ge: 0,
        ob_price: true
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },
  methods: {
    emitSelfSearch: function() {
      this.$root.$emit("searchParameters", this.searchParameters);
    }
  },
  components: {
    money: Money,
  }
};
</script>