var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.$route.name == 'main')?_c('banner'):_vm._e(),_c('div',{staticClass:"container"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false,"opacity":0.97,"z-index":2147480000},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('br')],1),(_vm.carousel && this.$route.name == 'main')?_c('Carousel'):_vm._e(),_c('div',{staticClass:"container"},[(_vm.showFilter)?_c('FilterComponent'):_vm._e(),(_vm.productList && _vm.productList.length)?_c('div',{ref:"plistRef",staticClass:"fx fx-wrap main-product-flex fx-center p-list"},_vm._l((typeof _vm.maxProducts !== 'undefined'
          ? _vm.productList.slice(0, _vm.maxProducts)
          : _vm.productList),function(product){return _c('product-item',{key:product.id,attrs:{"product":product}})}),1):_vm._e(),(
        (!_vm.productList || !_vm.productList.length) &&
        !_vm.isLoading &&
        _vm.searchParameters &&
        (_vm.searchParameters.name ||
          (_vm.searchParameters.categories && _vm.searchParameters.categories.length))
      )?_c('div',{staticClass:"fx fx-center fx-start fx-wrap container"},[_c('div',{staticClass:"btn clickable",on:{"click":function($event){return _vm.resetSearch()}}},[_vm._v("\n        Confira toda a Loja\n      ")])]):_vm._e(),(_vm.enable_view_more)?_c('div',{staticClass:"viewMore"},[_c('div',{staticClass:"btn clickable mg-1-top",on:{"click":function($event){return _vm.listProducts(true)}}},[_vm._v(" \n        Ver Mais \n      ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }